"use client";
import SettingsProvider from "@wt/game/providers/settings/provider";
import ToastifyProvider from "@wt/game/providers/toasts";
import { ThemeProvider } from "next-themes";
import { ReactNode } from "react";

import { BannerAdOpenProvider } from "../bannerAdOpen/BannerAdOpenProvider";
import { RoundProgressProvider } from "../roundProgress/RoundProgressProvider";
import { UserProvider } from "../user/UserProvider";
import { MuiThemeProvider } from "./MuiThemeProvider";


export const Providers = ({ children }: { children: ReactNode }) => {
  return (
    <ThemeProvider defaultTheme="dark" enableSystem={false}>
      <MuiThemeProvider>
        <UserProvider>
          <SettingsProvider>
            <RoundProgressProvider>
              <BannerAdOpenProvider>
                {children}
              </BannerAdOpenProvider>
            </RoundProgressProvider>
          </SettingsProvider>
          <ToastifyProvider />
        </UserProvider>
      </MuiThemeProvider>
    </ThemeProvider>
  );
};
