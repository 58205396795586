"use client";

import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import { useBannerAdOpen } from '@wt/game/providers/bannerAdOpen/BannerAdOpenProvider';
import { destroyBannerAd,refreshBannerAd } from "@wt/shared/hooks/useAds/useAds";
import { usePathname } from 'next/navigation';
import React, {useMemo} from "react";

const BannerAd = () => {
    const { adOpen, setAdOpen } = useBannerAdOpen();
    const pathname = usePathname();

    if (pathname.includes('admin')) {
      return <></>;
    }

    const adSize = useMemo(() => {
        return adOpen ? 'h-[50px] lg:h-[90px]' : 'h-0';
    },[adOpen]);

    const top = useMemo(() => {
      return adOpen ? 'top-[50px] lg:top-[90px]' : 'top-0';
    },[adOpen]);

    return (
      <div className="sticky top-0 z-50 flex w-full max-w-full flex-col bg-base-100">
        <div className="relative flex w-full flex-col items-start justify-start bg-base-100">
          <div
            className={`flex w-full shrink flex-col items-center justify-center bg-base-200 pb-1 ${adSize}`}
          >
            <div
              className={`flex w-full shrink items-center justify-center overflow-hidden bg-base-200 ${adSize}`}
            >
              <div id="adngin-top_banner-0"></div>
            </div>
          </div>
          <div
            className={`absolute left-0 ${top} flex h-4 w-8 shrink items-center justify-center overflow-hidden rounded-br-md bg-base-200`}
          >
            <button
              className={`duration-500 ${adOpen ? 'rotate-180' : 'rotate-0'}`}
              onClick={() => {
                adOpen ? destroyBannerAd() : refreshBannerAd();
                setAdOpen(!adOpen);
              }}
            >
              <ExpandMoreRoundedIcon />
            </button>
          </div>
        </div>
      </div>
    );
};

export default BannerAd;